import React, { Component } from "react";
import Slider from "react-slick";
import { Text, Heading } from "@chakra-ui/react";
import example1 from "../assets/example1.png";
import example2 from "../assets/example2.png";
import example3 from "../assets/example3.png";

export default class Carousel extends Component {
  componentDidMount() {
    let style = document.createElement("style");
    style.innerHTML = `
      .slick-prev:before,
      .slick-next:before {
        color: black !important;
      }
    `;
    document.head.appendChild(style);
  }

  constructor(props) {
    super(props);
    this.state = { showZoomedImage: false, zoomedImageSrc: "" };
  }

  handleImageClick = (src) => {
    this.setState({ showZoomedImage: true, zoomedImageSrc: src });
  };

  closeZoomedImage = () => {
    this.setState({ showZoomedImage: false, zoomedImageSrc: "" });
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    };

    const imageStyle = {
      width: "200px",
      height: "auto",
      display: "block",
      margin: "0 auto",
      boxShadow: "2px 2px 10px 0 rgba(0,0,0,0.2)",
      transition: "boxShadow .3s",
      cursor: "pointer",
    };

    const zoomedImageStyle = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
      height: "90%",
      zIndex: "9999",
    };

    const overlayStyle = {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: "9998",
    };

    const { showZoomedImage, zoomedImageSrc } = this.state;

    return (
      <div style={{ marginLeft: '40px', marginRight: '20px', marginTop: '40px'}}>
        <Heading
          lineHeight={0.8}
          fontWeight={600}
          fontSize={{ base: "3xl", sm: "2xl", lg: "3xl" }}
        >
          <Text
            as={"span"}
            position={"relative"}
            fontFamily={"'Open Sans', sans-serif"}
            _after={{
              content: "''",
              width: "full",
              height: "30%",
              position: "absolute",
              bottom: 1,
              left: 0,
              bg: "rgb(89,158,217)",
              zIndex: -1,
            }}
          >
            Exemples
          </Text>
        </Heading>
        {showZoomedImage && (
          <div style={overlayStyle} onClick={this.closeZoomedImage}>
            <img
              style={zoomedImageStyle}
              src={zoomedImageSrc}
              alt="Zoomed example"
            />
          </div>
        )}
        <Slider {...settings}>
          <div>
            <img
              style={imageStyle}
              src={example1}
              alt="Example 1"
              onClick={() => this.handleImageClick(example1)}
            />
          </div>
          <div>
            <img
              style={imageStyle}
              src={example2}
              alt="Example 2"
              onClick={() => this.handleImageClick(example2)}
            />
          </div>
          <div>
            <img
              style={imageStyle}
              src={example3}
              alt="Example 3"
              onClick={() => this.handleImageClick(example3)}
            />
          </div>
        </Slider>
      </div>
    );
  }
}
