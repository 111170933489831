import { Box, Flex, Link, Image, Button, Icon, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { FaLinkedinIn } from 'react-icons/fa';
import { FiChevronDown  } from 'react-icons/fi';
import { useState, useRef } from 'react';

function TopMenu() {

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const closeTimerRef = useRef(null);

  // const shouldBreakText = useBreakpointValue({ base: true, md: true, lg: false });

  const onOpen = () => {
    clearTimeout(closeTimerRef.current);  // Clear any existing timers
    setIsOpen(true);
  };

  const startCloseTimer = () => {
    // Use a timeout to close the menu after 1 second
    closeTimerRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };


  return (
    <Box bg="white" marginBottom="23px" marginLeft="33px" marginTop="24px" marginRight="24px">
      <Flex direction="row" alignItems="center" justifyContent="center"  w="100%">

        {/* Logo */}
        <Link href="https://entreprendre.ch">
          <Image 
            src="https://entreprendre.ch/wp-content/uploads/2020/04/Logo_empty_noir-012x.png"
            alt="Entreprendre.ch Logo"
            height="40px"
            minWidth="269px"
          />
        </Link>

        <Box flex={1} /> {/* This empty box will take up all available space and push the navigation to the right */}

        {/* Navigation */}
        <Flex fontFamily={"'Open Sans', sans-serif"}>
          
          {/* Fiches pratiques dropdown menu */}
          <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <MenuButton 
            onMouseOver={onOpen}
            onMouseLeave={startCloseTimer}
            p={0}
            mr={5}
            backgroundColor="white"
            fontSize={14}
            fontWeight={500}
            position="relative"
            as={Button}
            _hover={{
              backgroundColor: "white",
            }}
            _active={{
          backgroundColor: "white",
              }}
              _focus={{
                boxShadow: "none",
              }}
            _after={{
              content: '""',
              bottom: "2px",
              position: "absolute",
              left: "50%",
              width: isOpen ? "100%" : "0%",
              height: "2px",
              backgroundColor: "rgb(89,158,217)",
              transition: "width 0.3s ease",
              transform: "translateX(-50%)",
            }}
            rightIcon={<FiChevronDown color={isOpen ? "rgb(89,158,217)" : "black"} />}>
            Fiches pratiques
          </MenuButton>

          <MenuList 
            mt={"15px"} 
            borderRadius={0} 
            onMouseEnter={onOpen} 
            onMouseLeave={startCloseTimer}
            zIndex={10}
          >
                  <MenuItem fontSize={11} _hover={{color: "rgb(89,158,217)", backgroundColor: "white"}}>
                  <Link href="https://entreprendre.ch/fiches-pratiques/association/">
                    Bien s'associer
                  </Link>
                </MenuItem>
                <MenuItem fontSize={11} _hover={{color: "rgb(89,158,217)", backgroundColor: "white"}}>
                  <Link href="https://entreprendre.ch/fiches-pratiques/assurances/">
                    Mieux s'assurer
                  </Link>
                </MenuItem>
                <MenuItem fontSize={11} _hover={{color: "rgb(89,158,217)", backgroundColor: "white"}}>
                  <Link href="https://entreprendre.ch/fiches-pratiques/business-plan/">
                    Rédiger son business plan
                  </Link>
                </MenuItem>
                <MenuItem fontSize={11} _hover={{color: "rgb(89,158,217)", backgroundColor: "white"}}>
                  <Link href="https://entreprendre.ch/fiches-pratiques/finance/">
                    Maîtriser ses finances
                  </Link>
                </MenuItem>
                <MenuItem fontSize={11} _hover={{color: "rgb(89,158,217)", backgroundColor: "white"}}>
                  <Link href="https://entreprendre.ch/fiches-pratiques/juridique-et-legal/">
                    Connaître son cadre légal
                  </Link>
                </MenuItem>
                <MenuItem fontSize={11} _hover={{color: "rgb(89,158,217)", backgroundColor: "white"}}>
                  <Link href="https://entreprendre.ch/fiches-pratiques/marketing/">
                    Exceller en marketing
                  </Link>
                </MenuItem>
                <MenuItem fontSize={11} _hover={{color: "rgb(89,158,217)", backgroundColor: "white"}}>
                  <Link href="https://entreprendre.ch/fiches-pratiques/organisation-rh/">
                    Gérer l'humain
                  </Link>
                </MenuItem>
                <MenuItem fontSize={11} _hover={{color: "rgb(89,158,217)", backgroundColor: "white"}}>
                  <Link href="https://entreprendre.ch/fiches-pratiques/strategie/">
                    Exécuter sa stratégie
                  </Link>
                </MenuItem>
                <MenuItem fontSize={11} _hover={{color: "rgb(89,158,217)", backgroundColor: "white"}}>
                  <Link href="https://entreprendre.ch/fiches-pratiques/ventes-developpement-commercial/">
                    Ventes & développement commercial
                  </Link>
                </MenuItem>
                  </MenuList>
            </Menu>



          <Link href="https://entreprendre.ch/notre-equipe/" isExternal>
            <Button
              fontSize={14}
              size="sm"
              p={0}
              backgroundColor="white"
              fontWeight={500}
              mr={6}
              mt={1}
              position="relative"
              _hover={{
                backgroundColor: "white",
                textDecoration: "none",
                color: "black",
                _after: {
                  width: "100%",
                  left: 0,
                },
              }}
              _after={{
                content: '""',
                position: "absolute",
                bottom: "1px",
                left: "50%",
                width: 0,
                height: "2px",
                backgroundColor: "rgb(89,158,217)",
                transition: "width 0.3s ease, left 0.3s ease",
              }}
            >
              Notre équipe
            </Button>
          </Link>
         <Link href="https://entreprendre.ch/nos-tarifs-creation-entreprise-suisse/" isExternal>
            <Button
              fontSize={14}
              size="sm"
              p={0}
              backgroundColor="white"
              fontWeight={500}
              mr={3}
              mt={1}
              position="relative"
              _hover={{
                backgroundColor: "white",
                textDecoration: "none",
                color: "black",
                _after: {
                  width: "100%",
                  left: 0,
                },
              }}
              _after={{
                content: '""',
                position: "absolute",
                bottom: "1px",
                left: "50%",
                width: 0,
                height: "2px",
                backgroundColor: "rgb(89,158,217)",
                transition: "width 0.3s ease, left 0.3s ease",
              }}
            >
              Nos Tarifs
            </Button>
          </Link>

          <Button as={Link} 
                  href="https://entreprendre.ch/start/"
                  size={"sm"}
                  fontWeight={500}
                  px={5}
                  colorScheme={"red"}
                  fontFamily={"'Open Sans', sans-serif"}
                  bg={"rgb(89,158,217)"} 
                  _hover={{ bg: "rgb(104,170,226)",
                            color: "white",
                            textDecoration: "None"}}
                  mx={2}>
           
            Créer mon entreprise
          </Button>

          <Button as={Link}
                  href="https://entreprendre.ch/modifier-ma-societe/" 
                  size={"sm"}
                  fontWeight={500}
                  px={"17px"}
                  color="black"
                  fontFamily={"'Open Sans', sans-serif"}
                  bg={"white"}
                  _hover={{ 
                    bg: "white",
                    color: "rgb(84,150,206)",
                    borderColor: "rgb(84,150,206)",
                    textDecoration: "None"
                  }}
                  mx={4}
                  borderWidth="2px"
                 borderColor="rgb(153,153,153)">
            Modifier mon entreprise
          </Button>

          <Button as={Link}
            href="https://executive.entreprendre.ch"
            size={"sm"}
            fontWeight={500}
            px={"19px"}
            color="black"
            fontFamily={"'Open Sans', sans-serif"}
            bg={"white"}
            _hover={{ 
                bg: "white",
                color: "rgb(84,150,206)",
                borderColor: "rgb(84,150,206)",
                textDecoration: "None"

            }}
            mr={"29px"}
            borderWidth="2px"
            borderColor="rgb(153,153,153)">
            Executive summary
        </Button>


          <Link href="https://www.linkedin.com/company/entreprendre-ch/" isExternal>
          <Button
            backgroundColor="white"
            mt={2}
            mr={8}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            position="relative"
            display="inline-block"
            height="20px"
            width="20px"
            overflow="hidden"
            boxSizing="border-box"
            _hover={{ 
              bg: "white",
              color: "rgb(84,150,206)",
              borderColor: "rgb(84,150,206)",
              textDecoration: "None"
            }}>

            <Icon 
              as={FaLinkedinIn} 
              color="black" 
              boxSize="17px"  
              transition="transform 0.2s" 
              position="absolute"
              top="0"
              transform={isHovered ? "translateY(-20px)" : "translateY(0)"}
            />

            <Icon 
              as={FaLinkedinIn} 
              color="rgb(89,158,217)" 
              boxSize="17px"  
              transition="transform 0.3s" 
              position="absolute"
              top="20px"
              transform={isHovered ? "translateY(-20px)" : "translateY(0)"}
            />
          </Button>
        </Link>

        </Flex>
      </Flex>
    </Box>
  );
}

export default TopMenu;
