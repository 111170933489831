import { useNavigate } from "react-router-dom";
import {
  Stack,
  Heading,
  Text,
  Button,
} from "@chakra-ui/react";
import TopMenu from "../components/TopMenu";

function Cancel() {
  const navigate = useNavigate();

  return (
    <div>
      <TopMenu />

        {/* Grey Gradient Div */}
        <div style={{
            height: '50px', // adjust the height as needed
            background: 'linear-gradient(to bottom, #e0e0e0, white )'
        }}>

        <Stack
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          mx={6}
          direction={{ base: "column", md: "row" }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontFamily={"'Open Sans', sans-serif"}
              fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
            >
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: "30%",
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "rgb(89,158,217)",
                  zIndex: -1,
                }}
              >
                Nous sommes désolé
              </Text>
              <br />
              <Text as={"span"} color={"rgb(89,158,217)"}>
                votre achat n'a pas été validé !
              </Text>
            </Heading>
          </Stack>
        </Stack>
        <Button
          onClick={() => navigate("/")}
          fontFamily={"'Open Sans', sans-serif"}
          rounded={"full"}
          size={"lg"}
          fontWeight={"normal"}
          px={6}
          mx={6}
          colorScheme={"red"}
          bg={"rgb(89,158,217)"}
          _hover={{  bg: "rgb(84,150,206)"  }}
        >
          Retour
        </Button>
        </div>
      </div>

  );
}

export default Cancel;
