// import logo from './logo.svg';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Container } from "react-bootstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cancel from "./pages/Cancel";
import Store from "./pages/Store";
import Success from "./pages/Success";
import CartProvider from "./contexts/CartContext";
//import responses from "./data/gpt-responses-mockup"
import { ChakraProvider } from "@chakra-ui/react";
import { PdfContext } from "./contexts/PdfContext";
import { useState } from "react";

function App() {
  const [contextValue, setContextValue] = useState(null);

  return (
    <PdfContext.Provider value={{ contextValue, setContextValue }}>
      <ChakraProvider>
        <CartProvider>
            <BrowserRouter>
              <Routes>
                <Route index element={<Store />} />
                <Route path="success" element={<Success />} />
                <Route path="cancel" element={<Cancel />} />
              </Routes>
            </BrowserRouter>
        </CartProvider>
      </ChakraProvider>
    </PdfContext.Provider>
  );
}

export default App;
