import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Heading,
  Text,
  Button,
  Box,
  Flex,
  Input,
  Textarea,
} from "@chakra-ui/react";
import TopMenu from "../components/TopMenu";


function Success() {
  const [pdfGenerated, setPdfGenerated] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [editableText, setEditableText] = useState([]);
  const [editedText, setEditedText] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const generatePdf = useCallback(() => {
    if (pdfGenerated) return; //If the PDF is already generated, don't run the function

    // Url for deployment : https://us-central1-iterator-pdf.cloudfunctions.net/expressApi/gptResponse
    // Url for development : http://localhost:4000/gptResponse

    fetch(
      "https://us-central1-iterator-pdf.cloudfunctions.net/expressApi/gptResponse",
      { mode: "cors" }
    )
      .then((response) => response.json())
      .then((data) => {
        const regex = /# (.+?)\n([\s\S]*?)\n\n/g;
        let match;
        let text = [];

        while ((match = regex.exec(data)) !== null) {
          const heading = match[1];
          const content = match[2].trim();
          text.push({ heading, content });
        }

        setEditableText(text);
        setPdfGenerated(true);
      });
  }, [pdfGenerated]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    //console.log(token);

    // Url for deployment : https://us-central1-iterator-pdf.cloudfunctions.net/expressApi/success
    // Url for development : http://localhost:4000/success

    fetch(
      "https://us-central1-iterator-pdf.cloudfunctions.net/expressApi/success",
      {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          token: token,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.validation) {
          generatePdf();
        } else {
          navigate("/cancel");
        }
      });
  }, [generatePdf, navigate]);

  const handleValidatedButtonClick = async () => {
    setIsLoading(true); // set loading state to true
    if (!isDownloaded) {
      const markdownContent = editableText
        .map((section) => {
          return `# ${section.heading}\n\n${section.content}`;
        })
        .join("\n\n");

      setEditedText(markdownContent);

      setIsValidated(true);
    } else {
      navigate("/");
    }
    setIsLoading(false); // set loading state back to false after processing
  };

  const handleDownloadedButtonClick = () => {
    if (!isDownloaded) {
      const encodedText = encodeURIComponent(editedText);
      fetch(
        // Url for deployment : https://us-central1-iterator-pdf.cloudfunctions.net/expressApi/download?gptResponse=${encodedText}
        // Url for development : http://localhost:4000/download?gptResponse=${encodedText}

        `https://us-central1-iterator-pdf.cloudfunctions.net/expressApi/download?gptResponse=${encodedText}`,
        {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      )
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfBlobUrl = URL.createObjectURL(pdfBlob);

          const downloadLink = document.createElement("a");
          downloadLink.href = pdfBlobUrl;
          downloadLink.download = "executive-summary.pdf";
          downloadLink.click();

          URL.revokeObjectURL(pdfBlobUrl);
        });
      setIsDownloaded(true);
    } else {
      navigate("/"); // Navigating to home page
    }
  };

  const handleEditButtonClick = () => {
    setIsValidated(false);
  };

  const handleTextChange = (sectionIndex, key) => (event) => {
    const updatedSections = [...editableText];
    updatedSections[sectionIndex][key] = event.target.value;
    setEditableText(updatedSections);
    //console.log(updatedSections);
  };

  return (

     <div>
      <TopMenu />

        {/* Grey Gradient Div */}
        <div style={{
            height: '50px', // adjust the height as needed
            background: 'linear-gradient(to bottom, #e0e0e0, white )',
        }}>

      <Stack
        mx={6}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            fontFamily={"'Open Sans', sans-serif"}
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
          >
            <Text
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "30%",
                position: "absolute",
                bottom: 1,
                left: 0,
                bg: "rgb(89,158,217)",
                zIndex: -1,
              }}
            >
              {!isDownloaded ? `Finaliser` : `Merci !`}
            </Text>
            <br />
            <Text as={"span"} color={"rgb(89,158,217)"}>
              {!isDownloaded
                ? `votre executive summary!`
                : `Téléchargement en cours...`}
            </Text>
          </Heading>
          <Text
          color={"gray.600"}>
            {isDownloaded
              ? "Un problème avec le téléchargement ? Contactez-nous :  contact@iterator.ch"
              : "Merci pour votre achat ! Vous pouvez éditer votre executive summary avant de le télécharger en PDF."}
          </Text>

          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
          >
            {isValidated && (
              <Button
                onClick={handleDownloadedButtonClick}
                rounded={"full"}
                size={"lg"}
                fontWeight={"normal"}
                px={6}
                colorScheme={"red"}
                bg={"rgb(89,158,217)"}
                _hover={{ bg: "rgb(89,158,217)" }}
              >
                {isDownloaded ? "Retour" : "Télécharger le PDF"}
              </Button>
            )}
          </Stack>
        </Stack>

        {!isDownloaded && (
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            flexDirection={"column"}
            w={"full"}
          >

            <Box
              position={"relative"}
              justify={"center"}
              align={"center"}
              height={"500px"}
              rounded={"2xl"}
              boxShadow={"2xl"}
              width={"full"}
              overflow={"auto"}
              m={"16px"}
              padding={"10px"}
              bg={"white"}
            >
              <>
                <Box m={"16px"}>
                  {editableText.map((section, index) => (
                    <div key={index}>
                      {isValidated ? (
                        <>
                          <Text fontSize="xl" fontWeight="bold" mb={5}>
                            {section.heading}
                          </Text>
                          <Text mb={5}>{section.content}</Text>
                        </>
                      ) : (
                        <>
                          <Input
                            fontSize="xl"
                            fontWeight="bold"
                            mb={5}
                            defaultValue={section.heading}
                            onChange={handleTextChange(index, "heading")}
                          />
                          <Textarea
                            mb={5}
                            defaultValue={section.content}
                            onChange={handleTextChange(index, "content")}
                          />
                        </>
                      )}
                      <br />
                    </div>
                  ))}
                </Box>
              </>
            </Box>
            {!isDownloaded && (
              <Button
                onClick={
                  isValidated
                    ? handleEditButtonClick
                    : handleValidatedButtonClick
                }
                rounded={"full"}
                size={"lg"}
                fontWeight={"normal"}
                px={6}
                fontFamily={"'Open Sans', sans-serif"}
                colorScheme={"red"}
                bg={"rgb(89,158,217)"}
                _hover={{ bg: "rgb(89,158,217)" }}
                isDisabled={isLoading}
              >
                {isLoading
                  ? "En traitement..."
                  : isValidated
                  ? "Modifier"
                  : "Valider"}
              </Button>
            )}
            <Text  fontFamily={"'Open Sans', sans-serif"} mt="50px" ml="30px" color={"gray.600"}>
                Un problème ou une suggestion ? Contactez-nous :  contact@iterator.ch
            </Text>
              
          </Flex>
        )}
      </Stack>
    </div>
   </div>

  );
}

export default Success;
