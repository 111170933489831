/* eslint-disable no-unused-vars */
import { Col } from "react-bootstrap";
import { productsArray } from "../data/productsStore";
import { useState, useEffect } from "react";
import Carousel from "../components/Carousel";
import TopMenu from "../components/TopMenu";

import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Icon,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";

const API_KEY = process.env.REACT_APP_API_KEY; // API Key for Chat-GPT API

// Messages de chargement
const loadingMessages = [
  "Création du résumé",
  "Création de la proposition de valeur",
  "Ciblage de deux cibles clients",
  "Analyse de la structure des coûts",
  "Analyse des sources de revenus",
  "Création de la feuille de route",
];

function Store() {
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [preview, setPreview] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [error, setError] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  // Copy of the preview
  const handleCopy = () => {
    const filteredPreview = preview.filter(
      (section) => section.heading && section.content
    );
    const previewText = filteredPreview
      .map((section) => `${section.heading}: ${section.content}`)
      .join("\n");
    navigator.clipboard.writeText(previewText);
    setIsCopied(true);
  };

  useEffect(() => {
    if (isTyping) {
      // Change le message toutes les 3 secondes
      let currentIndex = 0;

      const timer = setInterval(() => {
        // Get the current message
        const currentMessage = loadingMessages[currentIndex];
        setLoadingMessage(currentMessage);

        // Increment the counter
        currentIndex++;

        // If we've reached the end of the array, clear the interval
        if (currentIndex >= loadingMessages.length) {
          clearInterval(timer);
          // Optionally, reset currentIndex if you want to loop:
          // currentIndex = 0;
        }
      }, 5000);

      // Efface le timer lorsque le composant est démonté
      return () => clearInterval(timer);
    }
  }, [isTyping]);

  const handleSend = async () => {
    // When the button "Générer" is clicked, data is retrieved from ChatGPT and then sent to the server

    setResponse("");
    setPreview([]);
    setIsTyping(false);
    setIsLoaded(false);
    setError(null); // Reset error message

    setIsTyping(true);
    try {
      const response = await processMessageToChatGPT(message);
      setResponse(response);
      sendResponseToServer(response); // Here data are send to the server
      //console.log(response)

      const regex = /# (.+?)\n([\s\S]*?)\n\n/g;
      let match;
      let selectedText = [{}];
      let count = 0;

      while ((match = regex.exec(response)) !== null && count < 2) {
        const heading = match[1];
        const content = match[2].trim();
        selectedText.push({ heading, content });
        count++;
      }

      setPreview(selectedText);
      setIsTyping(false);
      setIsLoaded(true);
    } catch (error) {
      setError(
        "Oups, nous dépendons de tiers pour générer la réponse et quelque chose a mal tourné. Veuillez réessayer maintenant!"
      );
      setIsTyping(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  async function processMessageToChatGPT(message) {
    const apiRequestBody = {
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content:

          `Vous êtes un coach spécialisé dans la création d'entreprises et l'entrepreneuriat. Lorsque je vous présente une idée, veuillez fournir :
          
          Résumé : Une description brève de l'idée.
          Proposition de valeur : Une phrase qui détaille la valeur unique de cette idée.
          Deux cibles de clients : Deux groupes spécifiques qui bénéficieraient le plus de cette idée.
          Structure des coûts : Une brève description des principaux coûts associés.
          Sources de revenus : Les principales façons dont l'idée générera des revenus.
          Feuille de route : Les étapes clés pour réaliser cette idée.
          Trois prochaines étapes : Les trois actions immédiates à entreprendre.

          
          Veuillez présenter vos réponses au format MARKDOWN avec des titres de niveau 2 et des points à puces.`
        },
        { role: "user", content: message },
      ],
      temperature: 0.1,
    };

    const response = await fetch(
      "https://api.openai.com/v1/chat/completions",

      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + API_KEY,
          "Content-Type": "application/json",
        },

        body: JSON.stringify(apiRequestBody),
      }
    );

    const data = await response.json();
    return data.choices[0].message.content;
  }

  async function sendResponseToServer(data) {
    // This function send the data from chatGPT to the server

    // Url for deployment : https://us-central1-iterator-pdf.cloudfunctions.net/expressApi/gptResponse
    // Url for development : http://localhost:4000/gptResponse

    await fetch(
      "https://us-central1-iterator-pdf.cloudfunctions.net/expressApi/gptResponse", //the url in the server where data are sent

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ generatedText: data }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success!");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div>
      
      <TopMenu  />

            <div style={{ 
              height: '400px', 
              overflow: 'hidden', 
              marginBottom: '20px', 
              position: 'relative',
              backgroundImage: `url(https://entreprendre.ch/wp-content/uploads/2023/01/strategie-entreprise.jpg)`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
              }}>

            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: '#000', 
                opacity: 0.5, 
                pointerEvents: 'none' 
            }}>
  
    </div>

    <Text
        as={"span"}
        position={"absolute"} 
        bottom={"40%"} 
        left={"18%"} 
        zIndex={1}
        color={"white"}
        fontSize={"50px"}
        fontWeight={"bold"}
        fontFamily={"'Open Sans', sans-serif"}
        _after={{
            content: "''",
            width: "full",
            height: "30%",
            position: "absolute",
            bottom: 1,
            left: 0,
            bg: "rgb(89,158,217)",
            zIndex: -1,
        }}
    >
        Générez votre executive summary!
    </Text>
</div>

            
        <Stack
          spacing={{ base: 8, md: 10 }}
          direction={{ base: "column", md: "row" }}
          style={{ marginLeft: '40px', marginRight: '40px' }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
              
            >
              
            </Heading>
            <Text color={"gray.600"} 
>
              Grâce à un partenariat entre <a href="https://self-tech.ch/">IAI</a> et <a href="https://entreprendre.ch/">Entreprendre.ch</a>, vous pouvez
              générer votre executive summary en un clic. Il vous suffit
              d'écrire votre idée dans le champ ci-dessous, et nous nous
              occupons du reste!
            </Text>
            <Input
              placeholder="Entrez votre idée de business ici..."
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              borderWidth="2px"
              borderColor="rgb(153,153,153)"
            />
            {error && <Text color={"red.500"}>{error}</Text>}
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: "column", sm: "row" }}
            >
              <Button
                onClick={handleSend}
                size={"lg"}
                fontWeight={500}
                px={6}
                colorScheme={"red"}
                fontFamily={"'Open Sans', sans-serif"}
                bg={"rgb(89,158,217)"}
                _hover={{ 
                bg: "rgb(84,150,206)", 
                boxShadow: "lg" ,
                
               }}
              >
                {"Générer".toUpperCase()}
              </Button>
            </Stack>
            <Text color={"gray.600"} style={{ fontSize: '12px' }}>
              Important : notre générateur d'executive summary repose sur le modèle de langage gpt-4. Vos données sont traitées avec toute la confidentialité des normes SOC-2. Cliquez <a href="https://openai.com/enterprise-privacy"style={{ color: 'red' }} target="_blank" rel="noreferrer" >ici</a> pour en savoir plus. 
            </Text>
          </Stack>
          <Flex
            flex={1}
            justify={"center"}
            align={"center"}
            position={"relative"}
            flexDirection={"column"}
            w={"full"}
          >
          
            {(isTyping || isLoaded) && (
              <Box
                position={"relative"}
                justify={"center"}
                align={"center"}
                height={"full"}
                rounded={"2xl"}
                boxShadow={"2xl"}
                width={"full"}
                overflow={"hidden"}
                m={"16px"}
                padding={"10px"}
                bg={"white"}
              >
                {isTyping && (
                  <>
                    <Box m={"16px"}>
                      <Box mb={"24px"}>
                        <Text
                          as="b"
                          fontSize="2xl"
                          style={{ userSelect: "none" }}
                        >
                          Votre rapport est en cours de rédaction...
                        </Text>
                      </Box>
                      <Box>
                        <Text as="i" fontSize="2xl">
                          {loadingMessage} {/* display the loading message */}
                        </Text>
                      </Box>
                    </Box>
                  </>
                )}

                {preview.map((section, index) => (
                  <div key={index}>
                    <Heading
                      as="h3"
                      fontSize="xl"
                      fontWeight="bold"
                      mb={2}
                      style={{ userSelect: "none" }}
                    >
                      {section.heading}
                    </Heading>
                    <Text style={{ userSelect: "none" }}>
                      {section.content}
                    </Text>
                    <br />
                  </div>
                ))}

                {isLoaded && (
                  <Box>
                    <Button
                      p="10px"
                      position="absolute"
                      bottom="10px"
                      right="10px"
                      colorScheme="teal"
                      variant="ghost"
                      bg="teal.50"
                      _hover={{ bg: "teal.100" }}
                      onClick={handleCopy}
                    >
                      {isCopied ? "COPIÉ" : "COPIER"}
                    </Button>
                  </Box>
                )}
              </Box>
            )}

            {isLoaded && (
              <>
                {productsArray.map((product, idx) => (
                  <Col align="center" key={idx}>
                    <ProductCard style={{ border: "0px" }} />
                  </Col>
                ))}
              </>
            )}
          </Flex>
        </Stack>
        <Stack>
          <Carousel />
        </Stack>
        </div>
  );
}

const ProductCard = () => {
  const checkout = async () => {

    // Url for deployment : https://us-central1-iterator-pdf.cloudfunctions.net/expressApi/checkout
    // Url for development : http://localhost:4000/checkout


    await fetch("https://us-central1-iterator-pdf.cloudfunctions.net/expressApi/checkout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        //price for test
        //price: "price_1NHR7WDkkhGQAFLCNTqbhHeu",
        //quantity: 1,
        
        // price for real
        price: "price_1NkNL4DkkhGQAFLCTCKnPVwi",
        quantity: 1,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.url) {
          window.location.assign(response.url); // Forwarding user to Stripe
        }
      });
  };

  return (
    <>
      <Box m="16px">
        <Text as="b" fontSize="2xl">
          Aller plus loin?
        </Text>
      </Box>
      <Box m="16px">
        <Text fontSize="1xl" mb="0.5">
          {"\u2022 Executive Summary complet"}
        </Text>
        <Text fontSize="1xl" mb="0.5">
          {"\u2022 Modifiable"}
        </Text>
        <Text fontSize="1xl" mb="0.5">
          {"\u2022 Export en PDF"}
        </Text>
        <Text fontSize="1xl" mb="0.5">
          {"\u2022 Sans souscription"}
        </Text>
      </Box>

      <Button
        size={"lg"}
        fontWeight={500}
        px={6}
        colorScheme={"red"}
        fontFamily={"'Open Sans', sans-serif"}
        bg={"rgb(89,158,217)"}
        _hover={{ 
        bg: "rgb(84,150,206)", 
        boxShadow: "lg"}} 
        onClick={() => {
          checkout();
        }}
      >
        {"J'obtiens mon Executive Summary".toUpperCase()}
      </Button>

      <Text m={"16px"} color="green.600" fontSize="2xl" fontWeight={"bold"}>
        CHF 9
      </Text>
    </>
  );
};

export default Store;
